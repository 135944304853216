<template>
  <div class="col-12 p-md-3 p-2">
    <div class="row justify-content-center align-items-center" >
      <div class="col-11 col-md-auto text-center bold">
        <div class="tab text-center w-100">
          <div class="row m-0 justify-content-center align-items-center">
            <div class="col-6 col-md-auto" @click="updatePaymentType('onceOff')" :class="{active: value === 'onceOff'}">
              <div class="row m-0 justify-content-center align-items-center">
                <div class="col-auto p-0">
                  One-Off <span class="d-none d-md-inline-block">Donation</span>
                </div>
                <div class="col-auto p-0" v-if="value === 'onceOff'">
                  <ios-checkmark-icon class="inline-icon"  />
                </div>
              </div>
            </div>
            <div class="col-6 col-md-auto" @click="updatePaymentType('recurring')" :class="{active: value === 'recurring'}" v-if="user">
              <div class="row m-0 justify-content-center align-items-center">
                <div class="col-auto p-0">
                  Recurring <span class="d-none d-md-inline-block">Payment</span>
                </div>
                <div class="col-auto p-0" v-if="value === 'recurring'">
                  <ios-checkmark-icon class="inline-icon" />
                </div>
              </div>
            </div>
            <div class="col-6 col-md-auto relative" :class="{active: value === 'recurring'}" v-else @mouseover="isHover = true" @mouseleave="isHover = false">
              <div class="row m-0 justify-content-center align-items-center ">
                <div class="col-auto p-0">
                  Recurring <span class="d-none d-md-inline-block">Payment</span>
                </div>
                <div class="col-auto p-0" v-if="value === 'recurring'">
                  <ios-checkmark-icon class="inline-icon" />
                </div>
              </div>
              <div class="onhover py-3" v-if="isHover" @mouseover="isHover = true" @mouseleave="isHover = false">
                <div class="row m-0 h-100 justify-content-center align-items-center">
                  <div class="col-12 font14 mb-2">
                    Login to Setup a Recurring Payment
                  </div>
                  <div class="col-auto">
                    <Button color="green" btnText="Login/Register as a Donor" icon="arrow" size="smallmobile" @buttonClicked="$emit('login')">
                      <IconArrowForward />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue'))
  },
  props: ['modelValue'],
  emits: ['update:modelValue', 'login'],
  name: 'Cart Tabs',
  data () {
    return {
      isHover: false
    }
  },
  methods: {
    updatePaymentType (val) {
      this.value = val
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
<style scoped>
.tab {
  border-radius: 50px;
  padding: 3px;
  background-color: var(--green-color-dark);
  color: #fff;
  border: 3px solid var(--green-color);
  font-size: 11px;
  cursor: pointer;
}
.tab .active {
  background-color: var(--gold-color);
  color: var(--green-color-dark);
  border-radius: 50px;
  cursor: default;
}
.onhover {
  position: absolute;
  width: 215%;
  height: inherit;
  right: -10px;
  top: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  color: var(--normal-color);
  font-weight: normal;
  font-family: "quicksand", sans-serif;
  z-index: 2;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
}
@media (min-width: 992px) {
  .tab {
    font-size: 13px;
  }
  .onhover {
    width: 250%;
    left: -75%;
  }
}
</style>
